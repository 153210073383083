import React from 'react';
import styled from '@emotion/styled';

import Container from '@/components/atoms/Container';
import ContactBox from '@/components/molecules/ContactBox';

import mq from '@/styles/mq';

import ImageDataType from '@/types/data/image';

interface Props {
  background?: ImageDataType;
  title?: React.ReactNode;
  content?: React.ReactNode;
  button?: {
    text: React.ReactNode;
    link: string;
  };
}

const ServicesIndustryContactBoxRoot = styled(Container)`
  margin-top: ${({ theme }) => theme.spacing(3)};
  margin-bottom: ${({ theme }) => theme.spacing(6)};

  ${mq('lg')} {
    margin-top: ${({ theme }) => theme.spacing(5)};
    margin-bottom: ${({ theme }) => theme.spacing(10)};
  }
`;

const ServicesIndustryContactBox = (props: Props): JSX.Element => {
  return (
    <ServicesIndustryContactBoxRoot maxWidth={1040}>
      <ContactBox {...props} />
    </ServicesIndustryContactBoxRoot>
  );
};

export default ServicesIndustryContactBox;
