import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import Grid from '@/components/atoms/Grid';
import Typography from '@/components/atoms/Typography';

import mq from '@/styles/mq';

interface Props {
  title?: React.ReactNode;
}

const ServicesIndustryHeroRoot = styled(Grid)``;

const Section = styled.section`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(5)};
  margin-left: ${({ theme }) => theme.spacing(2)};
  margin-right: ${({ theme }) => theme.spacing(2)};
  /* margin-bottom: ${({ theme }) => theme.spacing(3)}; */
  ${mq('xxs')} {
    margin-top: ${({ theme }) => theme.spacing(10)};
    margin-left: ${({ theme }) => theme.spacing(5)};
    margin-right: ${({ theme }) => theme.spacing(4)};
    /* margin-bottom: ${({ theme }) => theme.spacing(4)}; */
  }
  ${mq('lg')} {
    margin-top: ${({ theme }) => theme.spacing(17)};
    margin-left: ${({ theme }) => theme.spacing(10)};
    margin-right: ${({ theme }) => theme.spacing(20)};
    /* margin-bottom: ${({ theme }) => theme.spacing(5)}; */
  }
`;

const Title = styled(Typography)`
  text-align: left;
`;

const ServicesIndustryHero = ({ title }: Props): JSX.Element => {
  return (
    <ServicesIndustryHeroRoot container>
      <Section>
        <Title variant="h1">{title}</Title>
      </Section>
    </ServicesIndustryHeroRoot>
  );
};

export const query = graphql`
  fragment ServicesIndustryHero on PrismicServicesIndustryPageDataType {
    hero_title {
      raw
    }
  }
`;

export default ServicesIndustryHero;
