import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Grid from '@/components/atoms/Grid';
import Background from '@/components/atoms/Background';
import Img from '@/components/atoms/Img';

import mq from '@/styles/mq';

import Typography from '@/components/atoms/Typography';

interface ServiceIndustryProps {
  title: React.ReactNode;
  content: {
    shortText: string;
    longText: string;
  }[];
  background: {
    url: string;
    alt: string;
  };
}

interface Props {
  services: ServiceIndustryProps[];
}

const ServicesIndustryPresentationRoot = styled.section`
  margin-bottom: ${({ theme }) => theme.spacing(7)};
`;

const Title = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  ${mq('lg')} {
    margin-bottom: ${({ theme }) => theme.spacing(4)};
  }
`;
const ServiceRoot = styled.section`
  width: 100%;
  /* display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; */
  /* ${mq('lg')} {
    width: 100%;
    justify-content: center;
    &:nth-of-type(odd) {
      flex-direction: row !important;
      padding-top: ${({ theme }) => theme.spacing(7)};
      padding-bottom: ${({ theme }) => theme.spacing(7)};
      > div:nth-of-type(1) {
        padding-right: ${({ theme }) => theme.spacing(10)};
        padding-left: ${({ theme }) => theme.spacing(10)};
      }
    }
    &:nth-of-type(even) {
      flex-direction: row-reverse !important;
      padding-top: ${({ theme }) => theme.spacing(7)};
      padding-bottom: ${({ theme }) => theme.spacing(7)};
      > div:nth-of-type(1) {
        padding-right: ${({ theme }) => theme.spacing(10)};
        padding-left: ${({ theme }) => theme.spacing(10)};
      }
    }
  } */
`;
const ServiceTitle = styled.div`
  /* flex-basis: 19%; */
  /* width: 379px; */
  width: 100%;
  h2 {
    text-align: left;
  }
  /* ${mq('lg')} {
    flex-basis: 379px;
  } */
`;

const TextContainer = styled.div`
  width: 100%;
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  padding-top: ${({ theme }) => theme.spacing(2)};
  p {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }
  ${mq('xxs')} {
    padding-left: ${({ theme }) => theme.spacing(5)};
    padding-right: ${({ theme }) => theme.spacing(5)};
    padding-bottom: ${({ theme }) => theme.spacing(5)};
    padding-top: ${({ theme }) => theme.spacing(5)};
  }
  ${mq('lg')} {
    width: 50%;
  }
`;
const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: 200px;
  }
  ${mq('xxs')} {
    width: 100%;
    img {
      width: 100%;
      height: 300px;
    }
  }
  ${mq('lg')} {
    width: 50%;
    img {
      width: 600px;
      height: 400px;
      border-radius: 10px;
    }
  }
`;
const ServiceContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  align-items: center;
  /* &:nth-of-type(1) {
    margin-bottom: ${({ theme }) => theme.spacing(10)};
  }
  ${mq('lg')} {
    flex-direction: row;
  } 
  */
  ${mq('lg')} {
    width: 100%;
    justify-content: center;
    &:nth-of-type(odd) {
      flex-direction: row !important;
      padding-top: ${({ theme }) => theme.spacing(7)};
      padding-bottom: ${({ theme }) => theme.spacing(7)};
      > div:nth-of-type(1) {
        padding-right: ${({ theme }) => theme.spacing(10)};
        padding-left: ${({ theme }) => theme.spacing(10)};
      }
    }
    &:nth-of-type(even) {
      flex-direction: row-reverse !important;
      padding-top: ${({ theme }) => theme.spacing(7)};
      padding-bottom: ${({ theme }) => theme.spacing(7)};
      > div:nth-of-type(1) {
        padding-right: ${({ theme }) => theme.spacing(10)};
        padding-left: ${({ theme }) => theme.spacing(10)};
      }
    }
  }
`;
const Text = styled(Typography)``;
const LongText = styled(Typography)`
  padding-right: ${({ theme }) => theme.spacing(2)};
  padding-left: ${({ theme }) => theme.spacing(2)};
  margin-top: ${({ theme }) => theme.spacing(3)};
  ${mq('xxs')} {
    padding-right: ${({ theme }) => theme.spacing(5)};
    padding-left: ${({ theme }) => theme.spacing(5)};
  }
  ${mq('lg')} {
    padding-right: ${({ theme }) => theme.spacing(10)};
    padding-left: ${({ theme }) => theme.spacing(10)};
    margin-top: ${({ theme }) => theme.spacing(0)};
  }
  p {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }
`;

const Service = ({
  title,
  content,
  background,
}: ServiceIndustryProps): JSX.Element => {
  return (
    <ServiceRoot>
      <ServiceContainer>
        <TextContainer>
          <ServiceTitle>
            <Title variant="h2">{title}</Title>
          </ServiceTitle>
          <Text variant="textMd">
            {content?.content_left}
            {content?.content_right}
            {/* {content?.longText} */}
          </Text>
        </TextContainer>
        <ImageContainer>
          <Img src={background?.url} alt={background?.alt} />
        </ImageContainer>
      </ServiceContainer>
      <LongText variant="textMd">{content?.longText}</LongText>
    </ServiceRoot>
  );
};

const ServicesIndustryPresentation = ({ services }: Props): JSX.Element => {
  return (
    <ServicesIndustryPresentationRoot>
      {services.map((service, index) => {
        const { title, content, background } = service;
        return (
          <Service
            key={index.toString()}
            title={title}
            content={content}
            background={background}
          />
        );
      })}
    </ServicesIndustryPresentationRoot>
  );
};

export const query = graphql`
  fragment ServicesIndustryPresentation on PrismicServicesIndustryPageDataType {
    services {
      service_title {
        raw
      }
      service_content_left {
        raw
      }
      service_content_right {
        raw
      }
      service_longtext {
        raw
      }
      service_background {
        url
        alt
      }
    }
    body1 {
      ... on PrismicServicesIndustryPageBody1Contactbox {
        id
        primary {
          type
          title {
            raw
          }
          content {
            raw
          }
          button_name {
            raw
          }
          link
          image {
            url
            alt
          }
        }
      }
    }
  }
`;

export default ServicesIndustryPresentation;
