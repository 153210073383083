import React from 'react';
import { RichText } from 'prismic-reactjs';
import styled from '@emotion/styled';

import ServicesIndustryHero from '@/containers/ServicesIndustry/ServicesIndustryHero';
import ServicesIndustryPresentation from '@/containers/ServicesIndustry/ServicesIndustryPresentation';
import ServicesIndustryContactBox from '@/containers/ServicesIndustry/ServicesIndustryContactBox';

import {
  PrismicServicesIndustryPage,
  PrismicServicesIndustryPageServicesGroupType,
  PrismicServicesIndustryPageBody1Contactbox,
} from '../../../graphql-types';

interface Props {
  data: {
    prismicServicesIndustryPage: PrismicServicesIndustryPage;
    prismicServicesIndustryPageBody1Contactbox: PrismicServicesIndustryPageBody1Contactbox;
  };
  type: string;
}

const ServicesIndustryContainerRoot = styled.section``;

const ServicesIndustryContainer = ({ data, type }: Props): JSX.Element => {
  const { hero_title, services } = data?.prismicServicesIndustryPage?.data;

  const { primary } = data?.prismicServicesIndustryPageBody1Contactbox || {};

  const HeroProps = {
    title: <RichText render={hero_title?.raw} />,
  };

  // const ServicesProps = {
  //   services: services.map(
  //     (el: PrismicServicesPageServicesGroupType | null) => {
  //       const { service_title, service_content } = el || {};

  //       return {
  //         title: <RichText render={service_title?.raw} />,
  //         content: <RichText render={service_content?.raw} />,
  //       };
  //     },
  //   ),
  // };

  // const ContactBoxProps = {
  //   title: <RichText render={primary?.title?.raw} />,
  //   content: <RichText render={primary?.content?.raw} />,
  //   button: {
  //     text: <RichText render={primary?.button_name?.raw} />,
  //     link: primary?.link,
  //   },
  //   background: {
  //     url: primary?.image?.url,
  //     alt: primary?.image?.alt,
  //   },
  // };

  const ContactBoxProps = {
    type: primary?.type,
    title: <RichText render={primary?.title?.raw} />,
    background: {
      url: primary?.image?.url,
      alt: primary?.image?.alt,
    },
    content: <RichText render={primary?.content?.raw} />,
    button: {
      text: <RichText render={primary?.button_name?.raw} />,
      link: primary?.link,
    },
  };

  // const ServicesProps = {
  //   services: Presentation.map((el) => {
  //     return {
  //       title: <RichText render={el?.primary?.service_title?.raw} />,
  //       content: el?.items?.map(item => ({
  //         shortText: item?.service_content?.text,
  //         longText: item?.service_longtext?.text,
  //       })),
  //     };
  //   }),
  // };

  const ServicesProps = {
    services: services?.map(
      (el: PrismicServicesIndustryPageServicesGroupType | null) => {
        const {
          service_title,
          service_content_left,
          service_content_right,
          service_longtext,
          service_background,
        } = el || {};

        return {
          title: <RichText render={service_title?.raw} />,
          content: {
            content_left: <RichText render={service_content_left?.raw} />,
            content_right: <RichText render={service_content_right?.raw} />,
            longText: <RichText render={service_longtext?.raw} />,
          },
          background: {
            url: service_background?.url,
            alt: service_background?.alt,
          },
        };
      },
    ),
  };

  return (
    <ServicesIndustryContainerRoot>
      <ServicesIndustryHero {...HeroProps} />
      <ServicesIndustryPresentation {...ServicesProps} />
      {primary && <ServicesIndustryContactBox {...ContactBoxProps} />}
    </ServicesIndustryContainerRoot>
  );
};

export default ServicesIndustryContainer;
